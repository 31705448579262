// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyA-Tj2wpe9hFgPuCSywW5lxyn3OM-ScIkI",
 

  authDomain: "charmespirit-59b44.firebaseapp.com",
 

  projectId: "charmespirit-59b44",
 

  storageBucket: "charmespirit-59b44.appspot.com",
 

  messagingSenderId: "537506783231",
 

  appId: "1:537506783231:web:c819008531ac63f97991ca"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Initialize Cloud Storage and get a reference to the service
 export const storage = getStorage(app);
