import React, { useEffect, useState } from "react";
import "./BabyHolder.css";
import BabyBanner from "./BabyBanner/BabyBanner";
import kitten from "../../bdd/baby.json";
import CardCat from "../CardCat/CardCat";

function BabyHolder() {
  const [available, setAvailable] = useState(0)
  const [bebeAmour, setBebeAmour] = useState()

  useEffect(()=>{
    {kitten.map((baby) => {
      if (baby.available) {
        setAvailable(available+1)
      }
    })}
        // Array to track the sequence of keypresses
        let sequence = [];
        // Time limit in milliseconds to detect rapid sequence
        const timeLimit = 2000; // 1 second
    
        // Event listener for keypresses
        document.addEventListener('keydown', (event) => {
          const key = event.key;
    
          // Add the key to the sequence array with a timestamp
          const currentTime = new Date().getTime();
          sequence.push({ key, time: currentTime });
    
          // Limit the array size to 3 (for 'a', 'b', 'c')
          if (sequence.length > 7) {
            sequence.shift(); // Remove the oldest entry
          }
    
          // Check if the sequence matches 'abc' and was pressed rapidly
          if (sequence.length === 7 &&
              sequence[0].key === 'v' &&
              sequence[1].key === 'a' &&
              sequence[2].key === 'n' &&
              sequence[3].key === 'i' &&
              sequence[4].key === 'l' &&
              sequence[5].key === 'l' &&
              sequence[6].key === 'e' &&
              (sequence[6].time - sequence[0].time <= timeLimit)) {
    
            setBebeAmour( <CardCat title="Vanille" img="https://firebasestorage.googleapis.com/v0/b/charmespirit-59b44.appspot.com/o/carte_bebe%2Fvanille.jpg?alt=media&token=99b4f439-8b1d-423b-982c-2b255a43e55b" name="vanille"/>)
            sequence = []; // Reset the sequence
          }
        });
  },[])
  
  return (
    <div className="babyholder-container">
    { available > 0 ? <BabyBanner title="Chatons disponibles" /> : <></>}
      <div className="baby-available">
        <div className="baby-container">
          {kitten.map((baby) => {
            console.log(baby.name);
              if (baby.available) {
                console.log(baby.name + " dispo");
                return <CardCat title={baby.name} img={baby.image} link="chaton" name={baby.name} />;
              }
          })}
        </div>
      </div>
      <div className="baby-notavailable">
        <BabyBanner title="Chatons non disponibles" />
        <div className="baby-container">
          {kitten.map((baby) => {
            if(baby.name !== "Vanille"){
              if (!baby.available) {
                console.log(baby.name + " non dispo");
                return <CardCat title={baby.name} img={baby.image} link="chaton" name={baby.name}/>;
              }
            }
          })}
          {bebeAmour}
        </div>
      </div>
    </div>
  );
}

export default BabyHolder;
