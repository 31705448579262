import React from 'react'
import MainLayout from '../../layout/MainLayout'
import BannerTitle from '../BannerTitle/BannerTitle';
import titi from "../ressources/titi/titi.webp"
import thalia from "../ressources/thalia/Thalia1.jpg"
import themis from "../ressources/themis/Themisjolie.jpg"
import PhotoGrid from '../PhotoGrid/PhotoGrid';
import BabyHolder from '../BabyHolder/BabyHolder';


function Bebe() {

const ptext4 = `Themis a accouché la nuit du 11 Avril 2024 (la même nuit que Thalia). 4 nouveaux chatons sont nés : 1 mâle et 3 femelles. Les photos ne sont pas encore disponibles.`

const ptext3 =  `Notre femelle Thalia nous a fait 3 magnifiques chatons. Il s'agit de 3 femelles, Vanille, Vaïana et Violette. Ces chatons sont venus au monde la nuit du 11 Avril 2024. Il y a une petite Lilac et deux Blue point`

const ptext = `Deux magnifiques chatons mâles sont nés le 4 juillet 2023 du mariage de Ti Amo et de Thémis.

Ils ne seront réservables qu'à partir de leur deux mois et de leur premier vaccin. Ils pourront nous quitter à l'âge de trois mois révolus pour leur assurer un bon équilibre et une séparation avec la maman dans les meilleures conditions.

Quelques photos de leur évolution.`

const ptext2 = `Ushuaïa femelle blue point et Urus mâle lilac point sont nés le 25 aôut 2023 du mariage de Ti Amo et Thalia et ne sont plus disponibles`;
const notAv = "Les chatons ont déjà rejoint leur famille"
const Av4 = "4 chatons sont disponibles à la réservation"
const Av3 = "3 chatons sont disponibles à la réservation"
  return (
    <MainLayout>
      {/* <BabyPresentation fcatname="Thalia" mcatname="" fcatimg={thalia} ptext={ptext3} subtitle={Av3}/>
      <PhotoGrid cat="Thalia_chatons_2" imgNbMax={6}/>
      <BabyPresentation fcatname="Thémis" mcatname="Ti Amo" fcatimg={themis} mcatimg={titi} ptext={ptext4} subtitle={Av3}/>
      <BabyPresentation fcatname="Thalia" mcatname="Ti Amo" fcatimg={thalia} mcatimg={titi} ptext={ptext2} subtitle="Les chatons ne sont plus disponibles"/>
      <PhotoGrid cat="petits2" imgNbMax={17}/>
      <BabyPresentation fcatname="Thémis" mcatname="Ti Amo" fcatimg={themis} mcatimg={titi} ptext={ptext} subtitle={notAv}/>
      <PhotoGrid cat="petits" imgNbMax={26}/> */}
      <BabyHolder/>
    </MainLayout>
  )
}

export default Bebe;