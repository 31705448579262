import React,{useRef, useState}from 'react';
import "./ContactForm.css";
import {useForm} from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser"
import {Alert} from "@mui/material"

function ContactForm() {

    const [verified,setVerified] = useState(null);
    const [defined,setDefined] = useState(false);
    const [succes,setSuccess] = useState(false);

    const form =useRef();

    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues:{
            name:"",
            email:"",
            message:"",
        }
    });

    const onSubmit = data =>{
        console.log("REQUETE");

        if(verified){
            emailjs.sendForm('service_ayvuq9o', 'template_l1fun7u', form.current,'KGNvGnROhy-qx8bf4')
            .then(function(response) {
               console.log('SUCCESS!', response.status, response.text);
               setVerified(false);
               setDefined(true);
               setSuccess(true);
            }, function(error) {
               console.log('FAILED...', error);
               setVerified(false);
               setDefined(true);
               setSuccess(false);
            });
        }
    }  

    const captcha = (value) =>{
        console.log(value)
        setVerified(true);
    }

    const displayAlert = () =>{
        setInterval(() =>{
            setDefined(true);
        },2500)
    }



  return (
    <div className='contactform-div-container'>
        <form  id="contactform-form" ref={form} onSubmit={handleSubmit(onSubmit)}>
        <label className='contactform-label'for="name">Nom</label>
        <input className='contactform-input' {...register("name",{required :'Votre nom est requis pour envoyer votre message',minLength:4 })} id="contactform-input-name" type="text" name="name" placeholder='Entrez votre nom'/>
        <p className='contactform-error'>{errors.name?.message}</p>
        <label className='contactform-label' for="email">E-mail</label>
        <input className='contactform-input' {...register("email",{required :'Votre email est requis pour envoyer votre message', pattern:{
            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message:"Votre adresse e-mail est incorrecte"
        }})} id="contactform-input-email"type="text" name="email" placeholder='Entrez votre e-mail'/>
        <p className='contactform-error'>{errors.email?.message}</p>
        <label className='contactform-label' for="message">Message</label>
        <textarea className='contactform-input'{...register("messageToSend",{required :'Votre message ne doit pas être vide pour être envoyé'})} id="contactform-input-message" type="text" name="messageToSend"placeholder='Entrez votre message'/>
        <p className='contactform-error'>{errors.messageToSend?.message}</p>
        <ReCAPTCHA id='captcha'
          sitekey="6LemOLsnAAAAAMp0SSsND8hpd5qzpO1a5UZI0zSe"
          onChange={captcha}
        />
        <button id="contactform-button"type="submit" onClick={displayAlert}>Envoyer </button>
        <div hidden={!defined}>
        <Alert id='contactform-alert' variant="filled" severity={succes ?"success":"error"}>
           {succes ? "Votre message a été envoyé avec succès !" : "Un problème technique et survenu, votre message n'a pas pu être délivré"}
        </Alert>
        </div>

        </form>
    </div>
  )
}

export default ContactForm