import MainLayout from "../../layout/MainLayout";
import imageDec from "../ressources/charmdeco.png"
import BannerTitle from '../BannerTitle/BannerTitle'
import { Link } from "react-router-dom";
import "./Deco.css"




function Deco(){

    return(

        <MainLayout>
            <BannerTitle title="Charme Spirit Déco" display={true}/>
            <div className="main-content-deco">

                            <span id="mainspan">En complément de notre élevage, nous développons une boutique en ligne d’articles de décoration sur le thème du chat. N’hésitez pas à aller le visiter ! Merci de cliquer sur le logo ci-dessous :</span>
            <Link to="https://www.charme-spirit.com/">
                <img id="img-deco" src={imageDec}></img>
            </Link>
            
            </div>

        </MainLayout>
    )
}

export default Deco