import React from "react";
import "./ParentPresentation.css";
import defImg from './default.jpg'

function ParentPresentation(props) {

  if(props.cat.link != ""){
    return (
      <div className="parentpresentation-container">
        <a href={props.cat.link}>
        <div className="parentpresentation-img">
          <img src={props.cat.img} id="parent-img" alt="cat"/>
        </div>
        <h1 id='parentpresentation-title'>{props.cat.name}</h1>
        </a>
  
      </div>
    );
  } else{
    return (
      <div className="parentpresentation-container">
        <a href={props.cat.link}>
        <div className="parentpresentation-img">
          <img src={defImg} id="parent-img" alt="cat"/>
        </div>
        <h1 id='parentpresentation-title'>{props.cat.name}</h1>
        </a>
  
      </div>
    );
  }


}

export default ParentPresentation;
