import React from "react";
import "./CategoryTitle.css";

function CategoryTitle(props) {
  return (
    <div className="categorytitle-container">
      <h2 id="categorytitle-title">{props.title}</h2>
      <div className="categorytitle-content">{props.children}</div>
    </div>
  );
}

export default CategoryTitle;
