import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import db from '../../bdd/db.json'
import CatPresentation from '../CatPresentation/CatPresentation'

function Chat() {
    const {name} = useParams()
    const [cat, setCat] = useState(db[0])

    useEffect(() => {
        db.forEach(element => {
            if(element.name === name){
                setCat(element)
            }
        });
    }, []) 

    return(
        <CatPresentation cat={cat} catname={name}/>

    )
}

export default Chat