import React from 'react'
import logo from '../ressources/LogoSiteMaman.PNG'
import "./MentionLegales.css"

function MentionLegales() {

  const t1="Réservation"
  const t2="Conditions de remise de l’animal"
  const t3="Le certificat d’engagement et de connaissance"
  const t4="Documents accompagnant la remise"
  const t5="Délai de remise du chat : "
  const t6="Réserve de propriété"
  const t7="Exclusion de garantie quant à la destination "
  const t8="Garanties"
  const t10="Garantie légale de conformité"
  const t11="Application du Code rural, action en vice rédhibitoire"
  const t12="Protection des données personnelles"
  const t13="Médiations"


  const p1 = `Toute réservation ou vente de chat/chaton effectuée par la SASU Chatterie de Charme Spirit est soumise aux présentes conditions générales de vente.`
  const p2 = `Cet article n’est applicable que si une réservation a été convenue entre les parties.
  Aucun chaton ne sera cédé avant l’âge de trois mois.
  Toutefois, il est possible de réserver un chaton à partir de l’âge de deux mois contre le versement d’arrhes convenues entre les parties. Une convention de réservation sera établie et signée entre les parties. Les présentes conditions générales de vente devront être remis et paraphés par les parties et feront partie intégrante de la convention de vente.
  Dans le cas où la réservation serait annulée par l’Acquéreur, l’Éleveur pourra conserver les arrhes versées. 
  Dans le cas où l’Éleveur annulerait la réservation, celui-ci devra rembourser intégralement les arrhes.`
  const p3 = `Le terme de remise dans ces conditions générales de vente doit être interprété au même sens que le terme juridique de livraison.`
  const p4 = `Le chat ne pourra être livré sans remise préalable obligatoire du certificat d’engagement remis par l’Éleveur à l’acquéreur au plus tard 7 jours avant la cession du chaton conformément aux dispositions de la loi du 30 novembre 2021.`
  const p5 = `Le chat sera cédé à l’acquéreur avec :
  • Un carnet de vaccination ;
  • Un certificat de bonne santé ;
  • Un kit chaton comprenant une notice d’élevage, un paquet de croquettes pour assurer la transition éventuellement avec une autre marque, quelques jouets ;
  • Une copie de la demande de pedigree auprès du LOOF ; et 
  • Sur demande une copie des pedigrees des parents.
L’Éleveur enverra dans les meilleurs délais le pedigree délivré par le LOOF à l’acquéreur par lettre en recommandé avec accusé de réception et procédera aux démarches nécessaires auprès de l’ICAD pour le changement de propriétaire.`
  const p6 = `L’Éleveur se réserve le droit de retarder la remise du chaton à l’Acquéreur dans le cas où le chaton présenterait des problèmes de santé ou s’il a un traitement, jusqu’à la fin du traitement en cours.`
  const p7 = `L’Éleveur conserve la propriété du chat objet de la présente jusqu’à ce qu’il ait encaissé la totalité de la somme convenue pour la vente et que cet encaissement conditionne le transfert de propriété. L’acheteur convient aussi qu’en compensation de la jouissance immédiate d’un animal dont l’Éleveur n’a pas encore encaissé la contrepartie financière, il assumera pendant cette période l’entière responsabilité de tous les risques de perte, vol, accident, décès, maladies dont pourrait être victime l’animal quelle qu’en soit la cause, même s’il s’agit d’un cas fortuit ou de force majeure. L’acheteur est informé et accepte que le volet B de la carte d’identification du chat ne soit adressé au Fichier I-CAD par l’Éleveur qu’après que ce dernier ait encaissé la totalité du montant convenu pour la vente.`
  const p8 = `L’Éleveur cède le chat pour compagnie c’est-à-dire que le chat n’est pas destiné à la reproduction et aucune garantie n’est apportée quant aux caractéristiques du chat aux fins de reproduction. De même aucune garantie n’est fournie en ce qui concerne d’éventuels résultats en exposition ou la couleur qui peut évoluer chez le Sacré de Birmanie.
  Il est rappelé que l’élevage de chats est soumis à des dispositions légales strictes et l’Acquéreur comprend que toute reproduction du chaton qui lui est confié devra se conformer aux exigences légales et règlementaires en vigueur.`
  const p9 = `Depuis le 1er janvier 2022, les ventes d’animaux domestiques ne sont plus soumises aux dispositions du code de la consommation relatives à la garantie légale de conformité et ne bénéficient donc plus d’une telle garantie. L’article L. 217-2 du Code de la consommation, modifié par l’article 9 de l’ordonnance n°2021-1247 du 29 septembre 2021 dispose que :« Les dispositions du présent chapitre ne sont pas applicables […] 3° Aux ventes d'animaux domestiques ».
  Toutefois, le consommateur bénéficie de la garantie contre les vices rédhibitoires prévue par le Code rural et de la pêche maritime, et également, par convention contraire, de la garantie légale des vices cachés (articles 1641 à 1649 du Code civil).
  Le consommateur bénéficie de l'action en garantie contre les vices rédhibitoires prévue par les articles L. 213-1 à L. 213-9 du code rural et de la pêche maritime. Cette garantie donne droit, dans les conditions et délais précisés par les dispositions de ce code, à une réduction de prix si l'animal est conservé ou à un remboursement intégral contre restitution de l'animal. 
  Ces dispositions sont entrées en vigueur le 1er octobre 2022.`
  const p10 = `En effet, dans les cas de maladies transmissibles à des espèces canine ou féline, l'action en garantie prévue par l’article L.213-1 du Code rural et de la pêche maritime ne peut être exercée que si un diagnostic de suspicion signé par un vétérinaire a été établi selon les critères définis par arrêté du ministre chargé de l'agriculture et dans les délais suivants pour :
  • la leucopénie infectieuse féline : cinq jours ;
  • la péritonite infectieuse féline : vingt et un jours ;
  • l'infection par le virus leucémogène félin : quinze jours.
Ces délais courent à compter de la remise de l’animal.`

  const p11=`Dans le cadre de son activité, la Chatterie de Charme Spirit est amenée à collecter et à traiter des données personnelles et agit en tant que responsable de traitement. Elle s’engage à respecter et à protéger les données personnelles qu’elle collecte dans le cadre de son activité en conformité avec la législation applicable en matière de données personnelles. La politique de protection des données personnelles accessible sur le site charme-spirit.fr décrit les catégories de données personnelles collectées, leur finalité et la base légale de leur traitement, leur durée de conservation, les destinataires de ces données et les droits des personnes concernées.  `
  const p12= `Toute réclamation devra faire l’objet d’ une démarche par écrit directement auprès de l’éleveur afin de permettre aux parties de résoudre leur litige. 
  En cas de désaccord persistant, une demande de médiation peut être effectuée auprès du Médiateur :
  Atlantique Médiation 5 mail du Front populaire 44200 NANTES Téléphone : 02 40 84 10 24
  L’Éleveur et l’Acquéreur tenteront de parvenir à un accord pour résoudre à l’amiable le litige qui les oppose, avec l’aide du médiateur saisi. A défaut d’accord amiable entre les parties, le médiateur leur propose une solution pour régler le litige.`



  return (
    <>
    <img src={logo} alt="logo" id="logo_cgv"></img>
    <h1 className='title_cgd'>Conditions générales de ventes</h1>
    <p className='p_cgd'>{p1}</p>
    <h1 className='title_cgd'>{t1}</h1>
    <p className='p_cgd' id='p2_cgd'>{p2}</p>
    <h1 className='title_cgd'>{t2}</h1>
    <p className='p_cgd'>{p3}</p>
    <h1 className='title_cgd'>{t3}</h1>
    <p className='p_cgd'>{p4}</p>
    <h1 className='title_cgd'>{t4}</h1>
    <p className='p_cgd'>{p5}</p>
    <h1 className='title_cgd'>{t5}</h1>
    <p className='p_cgd'>{p6}</p>
    <h1 className='title_cgd'>{t6}</h1>
    <p className='p_cgd'>{p7}</p>
    <h1 className='title_cgd'>{t7}</h1>
    <p className='p_cgd'>{p8}</p>
    <h1 className='title_cgd'>{t8}</h1>
    <h2 className='subtitle_cgd'>{t10}</h2>
    <p className='p_cgd'>{p9}</p>
    <h2 className='subtitle_cgd'>{t11}</h2>
    <p className='p_cgd'>{p10}</p>
    <h1 className='title_cgd'>{t12}</h1>
    <p className='p_cgd'>{p11}</p>
    <h1 className='title_cgd'>{t13}</h1>
    <p className='p_cgd'>{p12}</p>
    </>
  )
}

export default MentionLegales