import React from 'react'
import MainLayout from '../../layout/MainLayout'
import "./Accueil.css"
import titi from "../ressources/titi/titi.webp"
import titipouik from "../ressources/titi/titipouik.webp"
import charme from "../ressources/charme/Charme.webp"
import LeftBannerAcc from '../LeftBannerAcc/LeftBannerAcc'
import RightBannerAcc from '../RightBannerAcc/RightBannerAcc'



function Accueil() {

    const p1Text = `Bonjour et bienvenue sur mon site. ​​Il y a quinze ans, en parallèle de mon activité, je me suis lancée en créant un petit élevage familial de maine coons dans les règles de l'art. Ce fut une super expérience mais mes obligations professionnelles étant de plus en plus exigeantes, il m'a fallu cessé l'activité après cinq ans. Je mets fin à présent à ma carrière de juriste d'entreprise pour reprendre cette aventure mais cette fois ci pour des sacrés de Birmanie. En mémoire de mon mâle Maine coon, qui s'appelait Charme et qui a été l'amour de chat de ma vie, je dédie mon nouvel élevage en l'appelant Charme Spirit afin que son esprit me porte chance et m'accompagne tout au long de cette nouvelle aventure. Ce chat était exceptionnel et me manque énormément.`;

        const p2Text = `Titulaire du CETAC, j'ai obtenu également l'ACADED du Ministère de l'Agriculture après avoir suivi la formation adaptée auprès de l'école nationale vétérinaire d'Alfort à Paris. ​Notre élevage est familial. Nos chats vivent avec nous dans des conditions d'hygiène et de bien être appropriées. Ils sont vaccinés contre le coryza, le typhus et la leucose et ne sortent pas. Lorsque le temps le permet, ils ont un espace dédié à l'extérieur entièrement clôturé et protégé contre d'éventuels contacts.Ils sont identifiés génétiquement et testés PKD, nude et FeLV/FIV et ont leur pedigree LOOF. Nos minettes ne reproduisent qu'une seule fois à l'année afin de leur laisser le temps de bien récupérer.​`;

        const p3Text = `Nous souhaitons avoir une démarche écoresponsable en cumulant les petits gestes. Cela passe par le choix des litières et leur traitement (compostable) ou par l'utilisation de produits d'entretien plus écologiques.`;

        const p4Text = `Nous aimons les chats de race certes pour leur esthétique mais surtout car ils ont développé des qualités de proximité avec l'homme et leur attachement est souvent plus démonstratif. Nous aimons les chats européens car, s'ils sont souvent plus indépendants, ils ont chacun leurs particularités et leurs qualités. Il en faut donc pour tout le monde. Et aimer des chats de race n'empêche pas d'aimer nos chats de gouttière.Pour ces deux familles de chats, la réflexion s'impose avant d'adopter car il s'agit dans tous les cas d'une vraie responsabilité...​​`;

        const p5Text = `Si vous êtes dans la perspective d'acquérir un chaton de Birmanie ou même d'adopter un chaton européen, il est très important que vous consultiez la page Informations majeures. Bonne visite à vous!`;



  return (
    <MainLayout>
        <div className='all'>
        <div className="div-banner">
            <h2 className ="text-banner" id="left-text-banner">Bienvenue sur le site de notre élevage</h2>
            <img src={titipouik} alt="" className="center-image-banner"/>
            <h2 className ="text-banner" id="right-text-banner">Notre passion: le chat de race ... mais pas seulement</h2>
        </div>
        <div className="div-txt-und-ban">
            <h1 id="txt-under-ban">Notre conception de l'élevage</h1>
        </div>


        <div className="div-photo-titi">
            <img src={titi} alt="" id="img-titi"/>
        </div>
        <div className="div-content">
            <div className="div-img-right">
                <img src={titipouik} alt="" className="cat-picture" id="left-picture"/>
            </div>
            <div className="div-txt-content">
                <h1 className="main-title-body">Bienvenue</h1>
                <p id="p1" className='pAccueil'>{p1Text}</p>
                <p id="p2" className='pAccueil'>{p2Text}</p>
                <p id="p3" className='pAccueil'>{p3Text}</p>
                <p id="p4" className='pAccueil'>{p4Text}</p>
                <p id="p5" className='pAccueil'>{p5Text}</p>
            </div>
            <div className="div-img-left">
                <img src={charme} alt="" className="cat-picture" id="right-picture"/>
            </div>
        </div>
        </div>
    </MainLayout> )
}

export default Accueil