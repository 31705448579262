import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./BabyPresentation.css";
import CategoryTitle from "./CategoryTitle/CategoryTitle";
import kitten from "../../bdd/baby.json";
import ParentPresentation from "./ParentPresentation/ParentPresentation";
import PhotoGrid from "../PhotoGrid/PhotoGrid";

function BabyPresentation() {
  const { name } = useParams();
  const [cat, setCat] = useState(kitten[0]);


  useEffect(() => {
    kitten.forEach((element) => {
      if (element.name === name) {
        setCat(element);
      }
    });
  }, []);

  useEffect(() => {
    console.log("update");
  }, [cat]);


  return (
    <div className="babypresentation-container">
      <div className="babypresentation-name">
        <h1 id="baby-name">{cat.name}</h1>
      </div>
      <div className="babypresentation">
        {/* NOM DU CHAT */}

        <div className="babypresentation-left">
          {/* NAISSANCE DU CHAT */}

          <CategoryTitle title="Naissance">
            <p className="baby-info">{cat.birthday}</p>
          </CategoryTitle>

          <CategoryTitle title="Couleur">
            <p className="baby-info">{cat.couleur}</p>
          </CategoryTitle>

          {/* PARENTS DU CHAT */}

          <CategoryTitle title="Parents">
            <div className="parents-displayer" style={{ display: "flex" }}>
              <ParentPresentation cat={cat.parents[0]} />
              <ParentPresentation cat={cat.parents[1]} />
            </div>
          </CategoryTitle>

          <CategoryTitle title="Description">
            <p className="baby-info">
              {cat.description}
            </p>
          </CategoryTitle>

          {/* <CategoryTitle title="Photos" /> */}
        </div>
        <div className="babypresentation-right">
          <div className="bg-transparent"></div>
          <img id="baby-image" src={cat.image}></img>
        </div>
      </div>
      {/* <PhotoGrid cat={cat.folder} imgNbMax={cat.nb_img} /> */}
    </div>
  );
}
export default BabyPresentation;
