import React from "react"
import { Link } from "react-router-dom";
import "./Footer.css"

class Footer extends React.Component{
    

    render(){
        return(
        <div className="div-footer">
            <p className="footer-p"> </p>
            <Link className="footer-link" to="/mentions-legales">Conditions générales de vente</Link>
            <ul className="footer-ul">
                <li><Link to= "/">Accueil</Link></li>
                <li><Link to= "/important-informations">Informations majeures</Link></li>
                <li><Link to= "/chats-males">Le Prince</Link></li>
                <li><Link to= "/chats-femelles">Les Princesses</Link></li>
                <li><Link to= "/chats-bebes">Nos Bébés</Link></li>
                <li><Link to= "/contact">Contact</Link></li>
                <li><Link to= "/actualite">Actualités</Link></li>
            </ul>
        </div>
        )
    }
}

export default Footer;