import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Marquee from "react-fast-marquee";
import "./MainLayout.css";


function MainLayout({children}){

    const ptext = "Nous n'avons plus de chatons disponibles. Rendez-vous, nous l'espérons à l'été 2024!"
    const ptext2 = "Les chatons de Thalia et Thémis sont nés le 10 et 11 Avril 2024."
    return(
        <div className="layout-container">
        <Marquee className="marquee" pauseOnHover direction="left" speed={50}>
            <div className="marquee-txt-1">
               <span> {ptext2}</span>
            </div>
            <div className="marquee-txt-2">
               <span> {ptext2}</span>
            </div>
        </Marquee>
        <Header id="MainHeader"/>
        <div className="mainlayout-container" >
        {children}
        </div>
        <Footer id="MainFooter"/>
        </div>
    )
}
export default MainLayout;