import React, { useState, useEffect} from 'react';
import { storage } from '../../firebase';
import { listAll, ref } from 'firebase/storage';
import { getDownloadURL } from 'firebase/storage';
import {AiOutlineArrowLeft,AiOutlineArrowRight} from 'react-icons/ai'
import { ColorRing } from  'react-loader-spinner'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './PhotoGrid.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

function PhotoGrid(props) {
  console.log(props.cat+'/');

  const listRef = ref(storage,props.cat+'/');

  const [imgList, setImgList] = useState([]);
  const imgMax = props.imgNbMax;
  const [imageNb,setImageNb] = useState(0);


  function updateLiImg(){
    const buf = []
    listAll(listRef).then((res) => {
      const promises = res.items.map((photo) => {
        return getDownloadURL(photo).then((url) => {
          buf.push(url);
        })
      })

    return Promise.all(promises);
  }).then(()=>
    {setImgList(buf)}
  );

  console.log(imgList);
  console.log(imageNb);


  
  }

  function Loading(){
    return(
<ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#00000']}
/>
    )
  }

  function LeftArrow(){
    if(imageNb > 0){
      setImageNb(imageNb-1);
    }

}

function RightArrow(){
    
    if(imageNb < imgMax-1){
        setImageNb(imageNb +1);
    }
}


  useEffect(() => {
    updateLiImg(imgList);
    console.log(imgList);
    console.log(imageNb);
  },[]);



  return (
    <div className='photogrid-div-container'>
        <AiOutlineArrowLeft  onClick={LeftArrow} id='photogrid-icon-right'/>
        <AiOutlineArrowRight onClick={RightArrow} id='photogrid-icon-left'/>
        <LazyLoadImage 
        src={imgList[imageNb]} 
        className='photogrid-img' 
        alt="cat" 
        beforeLoad={Loading}
        />
    </div>
  );
}

export default PhotoGrid;
