import React,{useEffect, useState} from 'react'
import "./News.css"

function News(props) {

    const [expanded,setExpanded] = useState(false);
    const [mobile,setMobile] = useState(false);
    
    function setTextButton(bool){
        if (bool){
            return "Rétracter"
        }else{
            return "Voir plus"
        }
    }

    useEffect(()=>{
        setMobile(window.innerWidth <= 768);
    },[])

    const imgStyleExpanded = mobile ? {
        
        "width": '90%',
        "height": '200px',
        borderRadius: '20px',
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '150% , cover',
        backgroundPosition: '60% 50%',
        "transition": "1s",

    } : {
        'float':'right',
        "width": '400px',
        "height": '400px',
        borderRadius: '20px',
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '150% , cover',
        backgroundPosition: '60% 50%',
        "transition": "1s",

    };

    const imgStyle = mobile ? {
        "width": '50px',
        "height": '50px',
        borderRadius: '20px',
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '150% , cover',
        backgroundPosition: '60% 50%',
        "transition": "1s",
        'display' :'none'
    } : {
        'float':'right',
        "width": '200px',
        "height": '200px',
        borderRadius: '20px',
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '150% , cover',
        backgroundPosition: '60% 50%',
        "transition": "1s",
    };

    const divStyleExpanded = mobile ? {
        'height': '700px',
        'margin-top': '25px',
        'margin-bottom': '25px',
        'margin-left': '0%',
        'margin-right': '0%',
        'padding': '10px 0px',
        borderRadius: '20px',
        'overflow': 'hidden',
        'background-color': 'whitesmoke',
        "transition": "1s",
    } :{
        'height': '600px',
        'margin-top': '25px',
        'margin-bottom': '25px',
        'margin-left': '10%',
        'margin-right': '10%',
        'padding': '50px',
        borderRadius: '20px',
        'overflow': 'hidden',
        'background-color': 'whitesmoke',
        "transition": "1s",
    } ;

    const divStyle = mobile ?{
        'height': 'auto',
        'margin-top': '25px',
        'margin-bottom': '25px',
        'margin-left': '0%',
        'margin-right': '0%',
        'padding': '0%',
        borderRadius: '20px',
        'overflow': 'hidden',
        'background-color': 'whitesmoke',
        "transition": "1s"
    } : {
        'height': '300px',
        'margin-top': '25px',
        'margin-bottom': '25px',
        'margin-left': '10%',
        'margin-right': '10%',
        'padding': '50px',
        borderRadius: '20px',
        'overflow': 'hidden',
        'background-color': 'whitesmoke',
        "transition": "1s"
    };

  return (
    <div className='news-div-container'style={expanded ? divStyleExpanded : divStyle}>
        <div className='news-div-text-container' >
        <h1 id="news-title">{props.title}</h1>
        <p className="news-ptext">{props.ptext1}</p>
        <p className={expanded ? "news-ptext" : "none" }>{props.ptext2}</p>
        <p className={expanded ? "news-ptext" : "none" }>{props.ptext3}</p>

        </div>
        <div className='news-div-img-container'>
            <img style={expanded ? imgStyleExpanded : imgStyle} alt="" id="news-img" />
            <button id="news-button"onClick={
                () =>{setExpanded(!expanded); console.log(expanded);}
            }>{setTextButton(expanded)}</button>
        </div> 
    </div>
  )
}

export default News