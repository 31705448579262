import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import kitten from '../../bdd/baby.json'
import CatPresentation from '../CatPresentation/CatPresentation'
import BabyPresentation from '../BabyPresentation/BabyPresentation'

function Chaton() {
    const {name} = useParams()
    const [cat, setCat] = useState(kitten[0])

    useEffect(() => {
        kitten.forEach(element => {
            if(element.name === name){
                setCat(element)
            }
        });
    }, []) 

    return(
        <BabyPresentation cat={cat} catname={name}/>

    )
}

export default Chaton