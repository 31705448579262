import React from 'react'
import MainLayout from '../../layout/MainLayout'
import BannerTitle from '../BannerTitle/BannerTitle'
import News from '../News/News'
import PhotoGrid from '../PhotoGrid/PhotoGrid'
import bb from "../ressources/ulysUnity/bb1.jpg"
import bb2 from "../ressources/UshuaiaUrus/Deuxzamours.jpg"
import expo from "../ressources/news/expo.jpg"
import expo_limoge from "../ressources/news/Expo_Limoges.jpg"
import CardCat from '../CardCat/CardCat'
import CardDisplayer from '../CardDisplayer/CardDisplayer'



function Actualites() {

  const ptext1 = `Cette année 2023 nous avons eu la chance de voir naître deux beaux chatons de la part de Titi et Themis. Les deux chatons se nomment Ulys et Unity`
  const ptext2= `Ulys est seal point. C'est un chaton adorable, très sociable et joueur. Il a rejoint sa famille`
  const ptext3 = `Unity est lilac point. Il est très doux et calin. Il est reservé et a rejoint sa nouvelle famille le 7 Octobre. `


  const ptextp2 = `Nous avons eu la joie cet été d'avoir la première portée de Thalia et Ti Amo. `
  const ptext2p2 = `Ursula est une magnifique blue point qui a beaucoup de qualités de son papa.`
  const ptext3p2 = `Urus est un adorable et superbe lilac point qui a le charme réuni du papa et de la maman.`


  const ptext4 = `Ti Amo de l’Arche of Liberty et Thémis du Skironka participeront pour la première fois à l’exposition féline organisée par le Cercle félin de l’Ouest à Limoges les 2 et 3 décembre 2023.`


  const ptext51 = 
  `
  Un début encourageant en exposition pour Ti Amo et Thémis. Ti Amo a obtenu Excellent 1 pour ses 2 CAC (Certificat d'Aptitude au Championnat). Thémis, quant à elle, remporte son premier CAC - Excellent 1. Prochaine exposition à Niort les 27 et 28 janvier 2024.

 
  `



  return(
    <MainLayout>
        <BannerTitle title="Actualités" display={true}/>
        <News title="Résultats d'exposition de Limoges - Décembre 2023" ptext1={ptext51} ptext2="" ptext3='' img={expo_limoge}></News>
        <News title="Exposition" ptext1={ptext4} ptext2='' ptext3='' img={expo}></News>
        <News title="Nouvelle portée Aout 2023 " ptext1={ptextp2} ptext2={ptext2p2} ptext3={ptext3p2} img={bb2}></News>
        <News title="Nouvelle portée Juillet 2023" ptext1={ptext1} ptext2={ptext2} ptext3={ptext3} img={bb}></News>
    </MainLayout>
  )
}

export default Actualites