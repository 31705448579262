import React from 'react'
import "./CatPresentation.css"
import Header from '../Header/Header'
import PhotoGrid from '../PhotoGrid/PhotoGrid'

function CatPresentation(props) {
    const cat = props.cat
    console.log(props.catname)



    return (
        <>
            <div className='catpres-container'>
                <div className='text-container'>
                    <span id="main-title" className=''>{cat.title}</span>
                    <span id="cat-surname"className=''>{cat.surname}</span>
                    <div className='content-container-catpres'>

                    <div className='desc'>
                        <span id="cat-birthdate" className='text-catpres-desc'>Date de naissance :</span>         
                        <span id="cat-type" className='text-catpres-desc'>Couleur :</span>
                        <span id="cat-blood" className='text-catpres-desc'>Groupe Sanguin :</span>
                        <span id="cat-blood" className='text-catpres-desc'>Tests :</span>
                    </div>

                        <div className=''>
                        <span id="cat-birthdate" className='text-catpres'>{cat.birth_date}</span>
                        <span id="cat-type" className='text-catpres'>{cat.type}</span>
                        <span id="cat-blood" className='text-catpres'>{cat.blood_type}</span>
                        <ul>
                            {cat.tests.map((elem)=>{
                                return(<li>{elem}</li>)
                            })}
                        </ul>
                        </div>


                    </div>
                    <p id='description-cat'>{cat.description}</p>
                </div>
                <img id="catpres-img" width="50%" src={cat.image}></img>
            </div>
            <PhotoGrid cat={props.catname} imgNbMax={cat.nb_img}/>
        </>
    )
}

export default CatPresentation