import React from 'react'
import MainLayout from '../../layout/MainLayout'
import BannerTitle from '../BannerTitle/BannerTitle';
import ContactForm from '../ContactForm/ContactForm';
function Contact() {
  return (
    <MainLayout>
      <BannerTitle title="Nous Contacter" display={true}/>
      <ContactForm/>
    </MainLayout>
  )
}

export default Contact;