import React from "react";
import CardCat from "../CardCat/CardCat";
import "./CardDisplayer.css";

function CardDisplayer(props) {
  const listCat = props.list.map((elem) => {
    if (props.condition(elem)) {
      if (props.chaton) {
        <CardCat
          title={elem.title}
          link="chaton"
          img={elem.image}
          name={elem.name}
        ></CardCat>;
      } else {
        return (
          <CardCat
            title={elem.title}
            link="chat"
            img={elem.image}
            name={elem.name}
          ></CardCat>
        );
      }
    }
  });

  return <div className="card-list-container">{listCat}</div>;
}

export default CardDisplayer;
