import React from 'react';
import './BabyBanner.css';

function BabyBanner(props) {
  return (
<div className='babybanner-container'>
  <h1 id='babybanner-title'>{props.title}</h1>
</div>
  );
}

export default BabyBanner;
