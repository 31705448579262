import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Accueil from './components/pages/Accueil';
import InfoImportantes from './components/pages/InfoImportantes';
import Male from './components/pages/Male';
import Femelles from './components/pages/Femelles';
import Bebe from './components/pages/Bebe';
import Contact from './components/pages/Contact';
import Actualites from './components/pages/Actualites';
import MentionLegales from './components/pages/MentionLegales';
import Deco from './components/pages/Deco';
import Chat from './components/pages/Chat';
import Chaton from './components/pages/Chaton';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Accueil/>,
  },
  {
    path: "important-informations",
    element: <div><InfoImportantes/></div>,
  },
  {
    path: "chats",
    element: <div><Femelles/></div>,
  },
  {
    path: "chat/:name",
    element: <div><Chat/></div>,
  },
  {
    path: "chaton/:name",
    element: <div><Chaton/></div>,
  },
  {
    path: "chats-bebes",
    element: <div><Bebe/></div>,
  },
  {
    path: "contact",
    element: <div><Contact/></div>,
  },
  {
    path: "actualite",
    element: <div><Actualites/></div>,
  },  {
    path: "mentions-legales",
    element: <div><MentionLegales/></div>,
  },  {
    path: "charme-deco",
    element: <div><Deco/></div>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
