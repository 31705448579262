import React from 'react'
import "./BannerTitle.css"

function BannerTitle(props){    
  return (
    <div className='div-header'>
    <h3 id={props.display ? 'title-header':'none'}>{props.title}</h3>
    </div>
  )
}

export default BannerTitle;