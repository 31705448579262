import React from 'react'
import MainLayout from '../../layout/MainLayout'
import themis from "../ressources/themis/themis.webp"
import thalia from "../ressources/thalia/thaliamimi.jpg"
import CatPresentation from '../CatPresentation/CatPresentation';
import PhotoGrid from '../PhotoGrid/PhotoGrid';
import BannerTitle from '../BannerTitle/BannerTitle';
import CardDisplayer from '../CardDisplayer/CardDisplayer';
import db from '../../bdd/db.json'



function Femelles() {

  return (
    <MainLayout>
      {/* <BannerTitle title="Nos femelles" display={true}/> */}
      {/* <CatPresentation catname="Thémis du Skironca" img={themis} paragraphText={ptext}/>
      <PhotoGrid cat="themis" imgNbMax={17}/>
      <CatPresentation catname="Thalia du Manteau Neigeux" img={thalia} paragraphText={ptext2}/>
      <PhotoGrid cat="thalia" imgNbMax={5}/> */}
      <CardDisplayer list={db} condition={(e)=> true}/>
    </MainLayout>
  )
}

export default Femelles;