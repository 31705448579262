import React from 'react'
import './CardCat.css'
import { Link } from 'react-router-dom'

function CardCat(props) {

  return (
    <Link to={`/${props.link}/${props.name}`}>
    <div className='card-container'>
      <div className='card-img-container'>
      <img src={props.img} id='card-img' alt="cat" />
      </div>
        
        <h1 id='card-title'>{props.title}</h1>
    </div>
    </Link>
  )
}

export default CardCat