import React from 'react'
import MainLayout from '../../layout/MainLayout'
import BannerTitle from '../BannerTitle/BannerTitle';
import "./InfoImportantes.css"

function InfoImportantes() {
    const p1Text = `Si vous envisagez l'adoption d'un chaton, que ce soit de race ou non, il est impératif que ce choix soit éclairé et que vous ayez pleine conscience des devoirs et obligations qui vous incombent.

    Un chat n'est PAS un objet même si la loi les assimile à des biens "meubles" terminologie juridique qui n'est pas appropriée à mon sens. Il s'agit d'un être vivant doué d'une sensibilité et qui nécessite une attention et des soins à savoir une alimentation appropriée et des soins vétérinaires réguliers (rappel de vaccination; vermifuge; traitement anti-puce; stérilisation) et quelquefois ponctuels s'il tombe malade car tout comme nous, ils peuvent prendre froid, avoir mangé quelque chose qu'il ne fallait pas et nécessiter une consultation chez le vétérinaire. Cela implique un coût financier qui peut s'avérer non négligeable. Le coût d'acquisition n'est donc pas le seul coût à envisager. Si vous n'êtes pas sûr(e) de pouvoir assumer cela, n'adoptez pas de chat (ou même de chien)!`;
    const p2Text = `C'est pourquoi le décret 2022-1012 du 18 juillet 2022  est venu définir le contenu d'un Certificat d'engagement et de connaissance pour l'acquisition d'un chat applicable au 1er octobre 2022. Un exemplaire de ce certificat vous sera communiqué après votre visite de notre élevage si vous envisagez une adoption et devra nous être remis dûment daté et signé avant l'adoption prévue.`;
    const p3Text = `Vous pouvez envisager d'acquérir un chaton pour vous tenir compagnie ou vous vous dites que ce serait sympa d'avoir des chatons. Sachez que la reproduction de chats peut être qualifiée d'élevage au sens du décret d'application du 7 juin 2016:
    « On entend par élevage de chiens ou de chats l'activité consistant à détenir au moins une femelle reproductrice dont au moins un chien ou un chat est cédé à titre onéreux. » Je vous invite à consulter le lien suivant qui renvoie au site du LOOF qui renseigne bien toutes les obligations afférentes à cette profession:`;

  return (
    <MainLayout>
            <div className='all'>
            <BannerTitle title="Informations majeures" display={true}/>
            <div className='main-content'>
                <h1 id="main-title"></h1>

                <p id="p1"className='pInfoImp'>{p1Text}</p>
                <p id="p2"className='pInfoImp'>{p2Text}</p>
                <p id="p3"className='pInfoImp'>{p3Text}</p>
                <a href="https://www.loof.asso.fr/eleveurs/intro.php" id="link1">https://www.loof.asso.fr/eleveurs/intro.php</a>
            
            </div>
            </div>
    </MainLayout>
  )
}

export default InfoImportantes;