import React,{useState} from "react"
import { Link } from "react-router-dom";
import './Header.css';
import logo from '../ressources/LogoSiteMaman.PNG'
import {CiFacebook} from "react-icons/ci";



class Header extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
           expanded: true
        };
    }

    
    selectChild = (e) => {e.currentTarget.firstChild.click()};


    render(){
        return(
            <div className="div-nav">
                <div className="div-logo-title">
                    <img src={logo} id="logo_header" alt="logo Charme Spirit"></img>
                    <div className='text-header'>
                    <h1 id="title-page">Chatterie de Charme Spirit</h1>

                    <h2 id="title-page-2">Elevage familial et passionné de Sacrés de Birmanie</h2>


                    </div>

                    <a href="https://www.facebook.com/chatteriedecharmespirit" id="fb-logo-a">
                    <CiFacebook  id='fb-logo'></CiFacebook>
                    </a>

                </div>

                <div className="nav-container">
                    <ul className="main-ul">
                        <li onClick={this.selectChild}><Link to= "/">Accueil</Link></li>
                        <li onClick={this.selectChild}><Link to= "/actualite">Actualités</Link></li>
                        <li onClick={this.selectChild}><Link to= "/chats">Nos Chats</Link></li>
                        <li onClick={this.selectChild}><Link to= "/chats-bebes">Nos Bébés</Link></li>
                        <li onClick={this.selectChild}><Link to= "/contact">Contact</Link></li>
                        <li onClick={this.selectChild}><Link to= "/important-informations">Informations majeures</Link></li>
                        <li onClick={this.selectChild}><Link to= "/charme-deco">Charme Spirit Déco</Link></li>
                    </ul>
                </div>
        </div>
        )
    }
}

export default Header;